import api from '@flatfile/api'

export async function completeJob(jobId) {
	await api.jobs.complete(jobId, {
		outcome: {
			acknowledge: true,
			message: 'This import is now complete',
			next: {
				type: 'wait',
			},
		},
	})
}

export async function failJob(jobId, importError) {
	await api.jobs.fail(jobId, {
		outcome: {
			message: importError,
		},
	})
}
