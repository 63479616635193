import { failJob } from 'utils'
import { logException } from 'sentry'

async function handleImportError(jobId, importError, defaultMessage) {
	const errors =
		importError.response.data.errors.map((e) => e.title).join('\n') ||
		defaultMessage

	logException(errors)
	await failJob(jobId, errors)
}

export default handleImportError
