import { FlatfileListener } from '@flatfile/listener'
import { recordHook } from '@flatfile/plugin-record-hook'
import {
	completeJob,
	failJob,
	flatfileDataTypeValidations,
	getRowsAndFieldConfigs,
	splitNameByComma,
	handleImportError,
} from 'utils'
import { paymentRegisterSerializer } from 'serializers'
import { createPaymentRegisterData } from 'api'
import api from '@flatfile/api'

const PAYMENT_REGISTER_DATA_IMPORT_ERROR =
	'There was an issue importing payment register data. Please contact an administrator.'

function serializePaymentRegisterData(rows) {
	return rows.map((row) => paymentRegisterSerializer(row))
}

async function getFileName(spaceId) {
	const space = await api.spaces.get(spaceId)
	const fileId = space.data.metadata?.lastUploadedFileId
	if (fileId) {
		const file = await api.files.get(fileId)
		return file?.data?.name
	}

	return 'Imported Excel Data'
}

function paymentRegisterDataListener(planFiscalYearDataId, month, sheets) {
	const paymentRegisterSheet = sheets.find(
		(sheet) => sheet.slug == 'payment-register-data',
	)

	return FlatfileListener.create((listener) => {
		listener.use(
			recordHook('payment-register-data', (record) => {
				flatfileDataTypeValidations(record, paymentRegisterSheet.fields)
				splitNameByComma(record)
				flatfileDataTypeValidations(paymentRegisterSheet)
				return record
			}),
		)

		listener.on('file:updated', async ({ context: { fileId, spaceId } }) => {
			await api.spaces.update(spaceId, {
				metadata: { lastUploadedFileId: fileId },
			})
		})

		listener.filter(
			{ job: 'workbook:paymentRegisterData:submit' },
			(configure) => {
				configure.on(
					'job:ready',
					async ({ context: { jobId, workbookId, spaceId } }) => {
						try {
							const { rows } = await getRowsAndFieldConfigs(workbookId)
							const fileName = await getFileName(spaceId)

							try {
								const paymentRegisterData = serializePaymentRegisterData(rows)

								await createPaymentRegisterData(
									paymentRegisterData,
									planFiscalYearDataId,
									month,
									fileName,
								)

								await completeJob(jobId)
							} catch (importError) {
								await handleImportError(
									jobId,
									importError,
									PAYMENT_REGISTER_DATA_IMPORT_ERROR,
								)
							}
						} catch (error) {
							await failJob(jobId, error.message)
						}
					},
				)
			},
		)
	})
}

export default paymentRegisterDataListener
