const customValidations = {
	dateFieldKeys: [
		'dateOfBirth',
		'dateOfHire',
		'dateOfTermination',
		'dateOfRehire',
		'beneficiaryDateOfBirth',
		'dropEntryDate',
	],
	numberFieldKeys: [
		'yearlyEarnings',
		'annualizedPay',
		'ytdContributions',
		'ltdContributions',
		'monthlyBenefitAmount',
		'annualBenefitAmount',
		'supplementalBenefitAmount',
		'popUpAmount',
		'jointAndSurvivorPercentage',
		'tempSsBenefit',
		'lifeSsBenefit',
	],
}

function memberFlatfileValidations(record) {
	customValidations.dateFieldKeys.forEach((key) => {
		const value = record.get(key)
		if (!value) return

		if (isDateInFuture(value)) {
			record.addError(key, 'The date cannot be in the future')
		}
	})

	customValidations.numberFieldKeys.forEach((key) => {
		const value = record.get(key)
		if (!value) return

		if (isNonPositiveNumber(value)) {
			record.addError(key, 'Number cannot be negative')
		}
	})
}

function isDateInFuture(value) {
	const today = new Date()
	const inputDate = new Date(value)
	return inputDate > today
}

function isNonPositiveNumber(value) {
	return value < 0
}

export default memberFlatfileValidations
