import { FlatfileListener } from '@flatfile/listener'
import { autocast } from '@flatfile/plugin-autocast'
import { recordHook } from '@flatfile/plugin-record-hook'
import {
	completeJob,
	failJob,
	flatfileDataTypeValidations,
	handleImportError,
	transformSheetsToObject,
} from 'utils'
import { createHistoricalValuationData } from 'api'
import { get, snakeCase } from 'lodash'

function serializeSheetRows(rows) {
	if (!rows) return {}

	const serializedRows = rows.map((row) => {
		const transformed = {}
		const { values: obj } = row

		Object.keys(obj).forEach((key) => {
			const snakeCaseKey = snakeCase(key)
			const value = obj[key].value

			transformed[snakeCaseKey] = value
		})

		return transformed
	})
	return serializedRows
}

const HISTORICAL_VALUATION_DATA_IMPORT_ERROR =
	'There was an issue importing Valuation Report History. Please contact an administrator.'

function historicalValuationDataListener(planId, sheets) {
	const historicalValuationDataSheet = sheets.find(
		(sheet) => sheet.slug == 'historical-valuation-data',
	)
	const amortizationBasesSheet = sheets.find(
		(sheet) => sheet.slug == 'amortization-bases',
	)

	return FlatfileListener.create((listener) => {
		listener.use(autocast('historical-valuation-data', ['booleanField']))

		listener.use(
			recordHook('historical-valuation-data', (record) => {
				flatfileDataTypeValidations(record, historicalValuationDataSheet.fields)
				return record
			}),
		)

		listener.use(
			recordHook('amortization-bases', (record) => {
				flatfileDataTypeValidations(record, amortizationBasesSheet.fields)
				return record
			}),
		)

		listener.filter(
			{ job: 'workbook:valuationDataHistory:submit' },
			(configure) => {
				configure.on(
					'job:ready',
					async ({ context: { jobId, workbookId } }) => {
						try {
							const sheetRows = await transformSheetsToObject(workbookId)
							try {
								const valuationReportHistoryData = serializeSheetRows(
									get(sheetRows, 'historical-valuation-data'),
								)
								const amortizationBases = serializeSheetRows(
									get(sheetRows, 'amortization-bases'),
								)

								await createHistoricalValuationData(
									valuationReportHistoryData,
									amortizationBases,
									planId,
								)

								await completeJob(jobId)
							} catch (importError) {
								await handleImportError(
									jobId,
									importError,
									HISTORICAL_VALUATION_DATA_IMPORT_ERROR,
								)
							}
						} catch (error) {
							await failJob(jobId, error.message)
						}
					},
				)
			},
		)
	})
}

export default historicalValuationDataListener
