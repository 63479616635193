function flatfileDataTypeValidations(record, fieldDefs) {
	fieldDefs.forEach((field) => {
		const key = field.key
		const value = record.get(key)
		if (!value) return

		if (field.type == 'date' && isInvalidDateFormat(value)) {
			record.addError(key, 'Invalid Date Format')
		}
		if (field.type == 'number' && isInvalidNumberFormat(value)) {
			record.addError(key, 'Invalid Number Value')
		}
	})
}

function isInvalidDateFormat(value) {
	const valueArray = value.toString().split(/[-/]/)
	return valueArray.length !== 3
}

function isInvalidNumberFormat(value) {
	return isNaN(parseInt(value))
}

export default flatfileDataTypeValidations
