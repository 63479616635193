import { FlatfileListener } from '@flatfile/listener'
import { recordHook } from '@flatfile/plugin-record-hook'
import {
	pensionValuationReportDataSerializer,
	opebValuationReportDataSerializer,
} from 'serializers'
import {
	completeJob,
	failJob,
	flatfileDataTypeValidations,
	handleImportError,
	transformSheetsToObject,
} from 'utils'
import { updateValuationReport } from 'api'

const VALUATION_REPORT_IMPORT_ERROR =
	'There was an issue importing valuation report data. Please contact an administrator.'

function valuationReportDataListener(planFiscalYearDataId, sheets) {
	// OPEB Sheets
	const pvbCashSheet = sheets.find((sheet) => sheet.slug == 'pvb-cash')
	const pvbImplicitSheet = sheets.find(
		(sheet) => sheet.slug == 'pvb-implicit-subsidy',
	)
	const pvbTotalSheet = sheets.find((sheet) => sheet.slug == 'pvb-total')
	// Pension Sheet
	const projectedBenefitPaymentsSheet = sheets.find(
		(sheet) => sheet.slug == 'projected-benefit-payments',
	)

	return FlatfileListener.create((listener) => {
		listener.use(
			recordHook('pvb-cash', (record) => {
				flatfileDataTypeValidations(record, pvbCashSheet.fields)
				return record
			}),
		)

		listener.use(
			recordHook('pvb-implicit-subsidy', (record) => {
				flatfileDataTypeValidations(record, pvbImplicitSheet.fields)
				return record
			}),
		)

		listener.use(
			recordHook('pvb-total', (record) => {
				flatfileDataTypeValidations(record, pvbTotalSheet.fields)
				return record
			}),
		)

		listener.use(
			recordHook('projected-benefit-payments', (record) => {
				flatfileDataTypeValidations(
					record,
					projectedBenefitPaymentsSheet.fields,
				)
				return record
			}),
		)

		listener.filter(
			{ job: 'workbook:pensionValuationReportData:submit' },
			(configure) => {
				configure.on(
					'job:ready',
					async ({ context: { jobId, workbookId } }) => {
						try {
							const sheetRows = await transformSheetsToObject(workbookId)

							try {
								const valuationReportDataRecords =
									pensionValuationReportDataSerializer(sheetRows)
								await updateValuationReport(
									valuationReportDataRecords,
									planFiscalYearDataId,
								)

								await completeJob(jobId)
							} catch (importError) {
								await handleImportError(
									jobId,
									importError,
									VALUATION_REPORT_IMPORT_ERROR,
								)
							}
						} catch (error) {
							await failJob(jobId, error.message)
						}
					},
				)
			},
		)

		listener.filter(
			{ job: 'workbook:opebValuationReportData:submit' },
			(configure) => {
				configure.on(
					'job:ready',
					async ({ context: { jobId, workbookId } }) => {
						try {
							const sheetRows = await transformSheetsToObject(workbookId)

							try {
								const valuationReportDataRecords =
									opebValuationReportDataSerializer(sheetRows)
								await updateValuationReport(
									valuationReportDataRecords,
									planFiscalYearDataId,
								)

								await completeJob(jobId)
							} catch (importError) {
								await handleImportError(
									jobId,
									importError,
									VALUATION_REPORT_IMPORT_ERROR,
								)
							}
						} catch (error) {
							await failJob(jobId, error.message)
						}
					},
				)
			},
		)
	})
}

export default valuationReportDataListener
